import React, { useRef, useState, useEffect } from 'react'
import parse from 'html-react-parser'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { Link, graphql } from 'gatsby'

import { subscriptionInit, validateSubscriptionForm } from '../functions/reducers'
import { isBrowser, copyUrl } from '../functions/functions'
import { useForm } from '../functions/hooks'

import Layout from '../components/Layout'
import { NewsSubscription, MakeMore } from '../components/Sections'
import { Content, BreadCrumbs } from '../components/Atoms'
import { CheckBox } from '../components/Sections/ContactForm'
import { labels, localeUrls } from '../langs/langs'

import calendarI from '../images/calendar-big.svg'
import logo from '../images/logo.svg'
import enveI from '../images/envelope.svg'
import shareI from '../images/share.svg'
import checkedI from '../images/checked.svg'

const CopyUrlButton = ({ localeID }) => {
  const [urlCopied, setUrlCopied] = useState(false)
  const timerToHide = useRef()
  const handleOnClick = () => {
    copyUrl()
    setUrlCopied(true)
    clearTimeout(timerToHide.current)
    timerToHide.current = setTimeout(() => setUrlCopied(false), 5000)
  }
  return (
    <div className="hcenter">
      <img src={shareI} className="cursor-pointer" onClick={handleOnClick} />
      {urlCopied && (
        <p className="text-sm text-blue hcenter gap-2">
          URL copied to clipboard
          <img src={checkedI} className="w-3" />
        </p>
      )}
    </div>
  )
}

const Sticky = ({ localeID }) => {
  const { errors, handleInputChange, Submit } = useForm(
    50,
    subscriptionInit,
    validateSubscriptionForm,
    localeID,
    localeUrls[localeID]['THX_SUB']
  )
  useEffect(() => {
    handleInputChange({ target: { name: 'yourLang', value: localeID.slice(0, 2) } })
  }, [localeID])
  const mailUrl = isBrowser()
    ? `mailto:?subject=I wanted you to see this news from CCSTax&body=Check out this site ${window.location.href}.`
    : ''
  return (
    <div className="print:hidden max-w-sm xl:max-w-none hidden lg:block">
      <div className="sticky top-28">
        <h2 className="font-bold text-3xl">{labels[localeID]['NEWS_SUBSC']}</h2>
        <p className="font-medium pt-2 pb-8">
          {labels[localeID]['FOOTER_TIPS'].replace(/<\/?[^>]+(>|$)/g, '')}
        </p>
        <div className="hcenter gap-0">
          <input
            type="text"
            placeholder="Email"
            className={`bg-light h-12 w-96 max-w-full min-w-0 font-medium text-blue outline-none pl-4 pr-12 rounded-none border border-transparent ${classNames(
              { '!border-red': errors.yourMail }
            )}`}
            name="yourMail"
            onChange={handleInputChange}
          />
          <Submit className="button3 -ml-12" title={labels[localeID]['SUBSC']} />
        </div>
        <CheckBox
          onChange={handleInputChange}
          name="privacy"
          hasError={errors.privacy}
          className="mt-4"
          light={true}
          bgColor="bg-light"
        >
          {labels[localeID]['CF_AGREE1']}{' '}
          <Link className="underline" to={localeUrls[localeID]['GDPR']}>
            {labels[localeID]['CF_AGREE2']}
          </Link>
          .
        </CheckBox>
        <div className="py-10">
          <p>{labels[localeID]['SHARE_NEWS']}</p>
          <p className="hcenter pt-3">
            <a href={mailUrl} title="Share by Email">
              <img src={enveI} />
            </a>
            <CopyUrlButton localeID={localeID} />
          </p>
        </div>
        <div className="text-center relative rounded-2xl overflow-hidden isolate p-11">
          <StaticImage
            src="../images/coach.jpg"
            className="!absolute inset-0 -z-10"
            alt="background"
            quality={100}
          />
          <p className="text-white font-medium text-lg pb-8">
            {parse(labels[localeID]['MAKE_EFF'])}
          </p>
          <Link to={localeUrls[localeID]['CTA']} className="button2">
            {labels[localeID]['WORK_W_US']}
          </Link>
        </div>
        <button className="button3 mt-11" onClick={() => window.print()}>
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path
              d="M14.8791 5.50183C14.6591 5.50183 14.4391 5.52933 14.2191 5.55683C12.7341 2.55933 9.10413 1.34933 6.10664 2.83433C3.10914 4.31933 1.89914 7.94933 3.38414 10.9468C4.31914 12.8168 6.16164 14.0818 8.27914 14.2743V12.6243C5.85914 12.3218 4.15414 10.1493 4.42914 7.72933C4.73164 5.30933 6.90413 3.60433 9.32413 3.87933C11.3316 4.12683 12.9266 5.72183 13.1741 7.75683C14.3566 6.79433 16.0891 6.98683 17.0516 8.16933C18.0141 9.35183 17.8216 11.0843 16.6391 12.0468C16.1441 12.4318 15.5391 12.6518 14.9066 12.6518H13.8066V14.3018H14.9066C17.3266 14.3018 19.3066 12.3218 19.3066 9.90183C19.3066 7.48183 17.2991 5.50183 14.8791 5.50183Z"
              fill="currentColor"
            />
            <path
              d="M10.2043 9.90186V16.9969L8.58176 15.3744L7.42676 16.5294L10.4518 19.5544C10.7818 19.8844 11.3043 19.8844 11.6068 19.5544L14.6318 16.5294L13.4768 15.3744L11.8543 16.9969V9.90186H10.2043Z"
              fill="currentColor"
            />
          </svg>
          {labels[localeID]['DOWNLOAD_A']}
        </button>
      </div>
    </div>
  )
}

const NewsDetailPage = ({ location, data: { item }, pageContext: { localeID } }) => {
  console.log(item.tags)
  const gatsbyImageData = item?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  const mailUrl = isBrowser()
    ? `mailto:?subject=I wanted you to see this news from CCSTax&body=Check out this site ${window.location.href}.`
    : ''

  const translatedSlugArray = {
    sk_SK: localeUrls['sk_SK']['NEWS'],
    en_US: localeUrls['en_US']['NEWS'],
    de_DE: localeUrls['de_DE']['NEWS']
  }
  item.translated.map((translation) => {
    translatedSlugArray[translation.locale.id] = `${localeUrls[translation.locale.id]['NEWS']}/${
      translation.slug
    }`
  })
  return (
    <Layout
      location={location}
      title={labels[localeID]['NEWS']}
      localeID={localeID}
      translatedSlug={translatedSlugArray}
    >
      <BreadCrumbs>
        <Link to={localeUrls[localeID]['NEWS']}>{labels[localeID]['NEWS']}</Link>
        <span>/</span>
        <span className="text-dark-gray">{item.title}</span>
      </BreadCrumbs>
      <div className="pt-10 sm:pt-32 pb-11 print:pt-0">
        <Content>
          <img src={logo} className="hidden print:block mb-6" />
          <div className="lg:max-w-[50%] print:max-w-none">
            {!!item.tags.nodes.length && (
              <p className="text-gray font-medium">{item.tags.nodes[0].name}</p>
            )}
            <h1 className="text-3xl sm:text-5xl -tracking-[0.5px] font-bold py-4">{item.title}</h1>
            <p>
              {item.date} | <strong>{item.readingtimes.readingtime} min read</strong>
            </p>
          </div>
        </Content>
      </div>
      <div className="pb-24 sm:pb-32">
        <Content className="grid lg:grid-cols-[2fr_1fr] gap-8 xl:gap-14 print:grid-cols-1">
          <div>
            <article className="text-justify">
              <div
                className={`imgCon16 bg-blue mb-14 print:hidden ${classNames({
                  'flex items-center justify-center': !gatsbyImageData
                })}`}
              >
                {!!gatsbyImageData ? (
                  <GatsbyImage className="!absolute inset-0" image={gatsbyImageData} alt="test" />
                ) : (
                  <img className="absolute inset-0 m-auto w-36 h-36" src={calendarI} />
                )}
              </div>
              <div>{!!item.content && parse(item.content)}</div>
            </article>
            <div className="py-10 hcenter">
              <p>{labels[localeID]['SHARE_NEWS']}</p>
              <p className="hcenter">
                <a href={mailUrl} title="Share by Email">
                  <img src={enveI} />
                </a>
                <CopyUrlButton localeID={localeID} />
              </p>
            </div>
            <button className="button3 print:hidden" onClick={() => window.print()}>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M14.8791 5.50183C14.6591 5.50183 14.4391 5.52933 14.2191 5.55683C12.7341 2.55933 9.10413 1.34933 6.10664 2.83433C3.10914 4.31933 1.89914 7.94933 3.38414 10.9468C4.31914 12.8168 6.16164 14.0818 8.27914 14.2743V12.6243C5.85914 12.3218 4.15414 10.1493 4.42914 7.72933C4.73164 5.30933 6.90413 3.60433 9.32413 3.87933C11.3316 4.12683 12.9266 5.72183 13.1741 7.75683C14.3566 6.79433 16.0891 6.98683 17.0516 8.16933C18.0141 9.35183 17.8216 11.0843 16.6391 12.0468C16.1441 12.4318 15.5391 12.6518 14.9066 12.6518H13.8066V14.3018H14.9066C17.3266 14.3018 19.3066 12.3218 19.3066 9.90183C19.3066 7.48183 17.2991 5.50183 14.8791 5.50183Z"
                  fill="currentColor"
                />
                <path
                  d="M10.2043 9.90186V16.9969L8.58176 15.3744L7.42676 16.5294L10.4518 19.5544C10.7818 19.8844 11.3043 19.8844 11.6068 19.5544L14.6318 16.5294L13.4768 15.3744L11.8543 16.9969V9.90186H10.2043Z"
                  fill="currentColor"
                />
              </svg>
              {labels[localeID]['DOWNLOAD_A']}
            </button>
          </div>
          <Sticky localeID={localeID} />
        </Content>
      </div>
      <NewsSubscription localeID={localeID} />
      <MakeMore localeID={localeID} />
    </Layout>
  )
}

export default NewsDetailPage

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    item: wpPost(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "DD / MM / YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
            }
          }
        }
      }
      readingtimes {
        readingtime
      }
      tags {
        nodes {
          name
        }
      }
      translated {
        slug
        locale {
          id
        }
      }
    }
  }
`
